import React from "react";


import Headline from "../components/headline";
import Head from "../components/head";
import TextWrapAroundImg from "../components/textWrapAroundImg";

import giftStyles from "./giftAlz.module.css";

import {graphql} from "gatsby";

export const query = graphql`query{
  allContentfulTheGiftOfAlzheimersPage{
    edges{
      node{
        headerHeadline{
          headerHeadline
        }
        section1Headline{
          section1Headline
        }
        section1Paragraph{
          section1Paragraph
        }
        section2Headline{
          section2Headline
        }
        section2Paragraph{
          section2Paragraph
        }
        section3Headline{
          section3Headline
        }
        section3Paragraph{
          section3Paragraph
        }
        section3Image{
          file{url}
        }
        section3ImageCaption
        section4Headline{
          section4Headline
        }
        section4Image{
      	file{
          url
        }
        }
        section4Paragraph{
          section4Paragraph
        }
        section4Image2{
          file{
            url
          }
        }
        section4Image1Caption
        section4Image2Caption
        section5Headline{
          section5Headline
        }
        section5Paragraph{section5Paragraph}
        section6Headline{
          section6Headline
        }
        section6Paragraph{
          section6Paragraph
        }
        section7Headline{
          section7Headline
        }
        section7Paragraph{
          section7Paragraph
        }
        
        
      }
    }
  }
}`;


class giftOfAlzheimers extends React.Component {

  componentDidMount(){
    Array.from(document.querySelectorAll('p')).forEach(el => {
  
      let v=el.innerHTML;
      let p1=v.replace("Just Kids from The Bronx.", "<span style=font-style:italic>Just Kids from The Bronx.</span>")
      el.innerHTML=p1;
      });


      Array.from(document.querySelectorAll('p')).forEach(el => {
  
        let v=el.innerHTML;
        let p1=v.replace("To Kill a Mockingbird", "<span style=font-style:italic>To Kill a Mockingbird</span>")
        el.innerHTML=p1;
        });
    



    Array.from(document.querySelectorAll('p')).forEach(el => {
  
      let v=el.innerHTML;
      let p1=v.replace("The Secret Sits'", "<span style=font-style:italic>The Secret Sits'</span>")
      el.innerHTML=p1;
      });

      Array.from(document.querySelectorAll('p')).forEach(el => {
  
        let v=el.innerHTML;
        let p1=v.replace("Pirates of Penzance.", "<span style=font-style:italic>Pirates of Penzance.</span>")
        el.innerHTML=p1;
        });
        Array.from(document.querySelectorAll('p')).forEach(el => {
  
          let v=el.innerHTML;
          let p1=v.replace("Dead Man Walking", "<span style=font-style:italic>Dead Man Walking</span>")
          el.innerHTML=p1;
          });

          Array.from(document.querySelectorAll('p')).forEach(el => {
  
          let v=el.innerHTML;
          let p1=v.replace("Greensleeves", "<span style=font-style:italic>Greensleeves</span>")
          el.innerHTML=p1;
          });


          Array.from(document.querySelectorAll('p')).forEach(el => {
  
            let v=el.innerHTML;
            let p1=v.replace("Operavore", "<span style=font-style:italic>Operavore</span>")
            el.innerHTML=p1;
            });
  

            Array.from(document.querySelectorAll('p')).forEach(el => {
  
              let v=el.innerHTML;
              let p1=v.replace("The Secret Sits", "<span style=font-style:italic>The Secret Sits</span>")
              el.innerHTML=p1;
              });


              Array.from(document.querySelectorAll('p')).forEach(el => {
  
                let v=el.innerHTML;
                let p1=v.replace("Listen Here", "<a style='color:#ba0c2f; text-decoration: none; font-weight:600;' href='https://www.youtube.com/watch?v=jWX3Z56_iiA' target='_blank'>Listen Here</a>")
                el.innerHTML=p1;
                });

  }
  
render(){
  
  return (
    <>
    <Head title="The gift of alzheimer's"></Head>
      <Headline
        accentColor="red"
        title={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.headerHeadline.headerHeadline}
        lightBackground={true}
      ></Headline>
      <section
        className={giftStyles.giftOfAlzSection}
        style={{ backgroundColor: "#fff" }}
      >
        <div>
          <h2>{this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section1Headline.section1Headline}</h2>
          <p style={{whiteSpace:"pre-wrap"}}>
           {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section1Paragraph.section1Paragraph}
          </p>
        </div>
        <div>
          <h2>{this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section2Headline.section2Headline}</h2>
          <p>
            {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section2Paragraph.section2Paragraph}
          </p>
        </div>
      </section>
      <TextWrapAroundImg
        imgRight={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section3Image.file.url}
        paragraphHeader={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section3Headline.section3Headline}
        imgText={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section3ImageCaption}
        lightBackground={true}
        noPadding={true}
      >
        {" "}
        <p style={{whiteSpace:"pre-wrap"}}>
          {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section3Paragraph.section3Paragraph}
        </p>
      </TextWrapAroundImg>
      <TextWrapAroundImg
        imgRight={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4Image.file.url}
        paragraphHeader={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4Headline.section4Headline}
        imgText={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4ImageCaption}
        lightBackground={true}
        noPadding={false}
      >
        <p>
         {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4Paragraph.section4Paragraph}
        </p>
        <div style={{ float: "right" }}>
          <img
            style={{
              float: "right",
              marginTop: "16px",
            }}
            src={this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4Image2.file.url}
          ></img>
          <p
            style={{
              padding: "0",
              margin: "0",
              clear: "both",
              paddingTop: "10px",
            }}
          >
            {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section4Image2Caption}
          </p>
        </div>
      </TextWrapAroundImg>

      <section
        className={giftStyles.lastMusicSection}
        style={{ backgroundColor: "#fff" }}
      >
        <div>
          <h2>{this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section5Headline.section5Headline}</h2>
          <p style={{whiteSpace:"pre-wrap"}}>
           {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section5Paragraph.section5Paragraph}
          </p>
        </div>
      </section>
      <section
        className={giftStyles.requiem}
        style={{ backgroundColor: "#fff" }}
      >
        <div>
          <h2>{this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section6Headline.section6Headline}</h2>
          <p style={{whiteSpace:"pre-wrap"}}>
            {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section6Paragraph.section6Paragraph}
          </p>
          <div>
            <p id={giftStyles.betterMusic}>{this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section7Headline.section7Headline}</p>
          </div>
          <p style={{whiteSpace:"pre-wrap"}}>
           {this.props.data.allContentfulTheGiftOfAlzheimersPage.edges[0].node.section7Paragraph.section7Paragraph}
          </p>
        </div>
      </section>
    </>
  );
}
}
export default giftOfAlzheimers;